import React from 'react';
import { HeadingMain } from '../../styles/styles';

   
    function TypeWritter (){  
      
      return (

        <HeadingMain>
          <p className="p1">Hi there, I'm Alex.</p>   
          <p className="p2">Front End <span>Developer</span>,</p>       
          <p className="p3">Minimalist and Coffee <span>Lover</span></p>
        </HeadingMain>      
      );

    }

    export default TypeWritter